
import { defineComponent, PropType } from 'vue'
import { fromNow } from '@/utils/format'
import { Review } from 'momai'
export default defineComponent({
  props: {
    reviews: Array as PropType<Review[]>
  },
  setup: (props, { emit }) => {
    return {
      fromNow,
      // 点赞或取消点赞评论
      likeReview: (r: Review) => {
        emit('like-or-unlike', r)
      }
    }
  }
})
